import { Account } from "@models/account";
import { User } from "@models/user";
import { useUserStore } from "@store/user.store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import * as StorageService from "@services/storage.service";


export const useAuth = () => {
  const { setActiveUser, activeUser, setAccounts, setActiveAccount, setActiveUserToken } = useUserStore();
  const navigate = useNavigate();

  useEffect(() => {
    const userFromStorage: User | null = StorageService.getActiveUser();
    const accountsFromStorage: Account[] | null = StorageService.getAccounts();
    const activeAccountFromStorage: Account | null = StorageService.getActiveAccount();
    const tokenFromStorage: string | null = StorageService.getActiveUserToken();

    if (userFromStorage && accountsFromStorage && activeAccountFromStorage && tokenFromStorage){
      setActiveUser(userFromStorage);
      setAccounts(accountsFromStorage);
      setActiveAccount(activeAccountFromStorage);
      setActiveUserToken(tokenFromStorage);
    } else {
      navigate("/sign-in");
    }
  }, [navigate]);

  return {
    user: activeUser,
  };
};
