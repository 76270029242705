import {Dispatch, SetStateAction, useState} from "react";
import {Key} from "react-aria-components";
import { useAsyncList } from "react-stately";
import { t } from "i18next";

import {CodeSearchResult} from "@models/code-search-result";
import { IcdClass } from "@models/icd-class";
import { IcdClassWithModifiers } from "@models/icd-class-with-modifiers";
import { OpsClass } from "@models/ops-class";
import { OpsClassWithModifiers } from "@models/ops-class-with-modifiers";
import * as IcdService from "@services/icd.service";
import * as OpsService from "@services/ops.service";
import {Icons} from "@components/icons";
import {MyComboBox, MyItem} from "@ui/combobox.rs";
import { Tab } from "@pages/case-page-details";
import { queryClient } from "@config/query.config";

const SearchForm = ({
  setSearchTextState,
  searchTextState,
  activeSection,
  handleSelectedSearchResult
}: {
  setSearchTextState: Dispatch<SetStateAction<string>>;
  searchTextState: string;
  activeSection: Tab;
  handleSelectedSearchResult: (code: IcdClass | OpsClass | IcdClassWithModifiers | OpsClassWithModifiers, isCodable: boolean) => Promise<void>
}) => {
  let list = useAsyncList<CodeSearchResult>({
    async load({ filterText }) {
      let list: CodeSearchResult[] = [];
      if (activeSection === "icdCodes" && filterText) {
        setSearchTextState(filterText);
        list = await queryClient.fetchQuery({queryKey: ["icdCodeResult", filterText], queryFn: () => IcdService.searchIcd(filterText, {limit: 10}), staleTime: 86400000});
        setSearchResults({list: list, activeSection: activeSection});
      } else if (activeSection === "opsCodes" && filterText) {
        setSearchTextState(filterText);
        list = await queryClient.fetchQuery({queryKey: ["opsCodeResult", filterText], queryFn: () => OpsService.searchOps(filterText, {limit: 10}), staleTime: 86400000});
        setSearchResults({list: list, activeSection: activeSection});
      }
      return {
        items: list,
      };
    }
  });

  const [searchResults, setSearchResults] = useState<
    | {
        list: CodeSearchResult[] | undefined;
        activeSection: Tab;
      }
    | undefined
  >(undefined);

const onSelectionChange = async (key: Key) => {
    const selectedItem = searchResults?.list?.find((item) => item.code === key);
    if (!selectedItem) return;
    const codeClass = activeSection === "icdCodes"
      ? await IcdService.getIcdClass(selectedItem.code)
      : await OpsService.getOpsClass(selectedItem.code); 
    handleSelectedSearchResult(codeClass, selectedItem.isCodable);
};

  return (
    <MyComboBox
      placeholder={activeSection === "icdCodes" ? t("search_for_icd_codes") as string : t("search_for_ops_codes") as string}
      className="w-full text-sm"
      defaultInputValue={searchTextState}
      items={list.items}
      isLoading={list.isLoading}
      inputValue={list.filterText}
      onInputChange={list.setFilterText}
      onSelectionChange={(e) =>onSelectionChange(e as Key)}
    >
      {(item) => (
        <MyItem key={item.code} id={item.code}>
          <div className="flex items-center justify-between mx-2 gap-2 hover:bg-muted px-2 rounded-md cursor-pointer">
            <div className="flex flex-col mb-2">
              <div className="text-sm">{item.code}</div>
              <div className="text-xs text-muted-foreground truncate">
                {item.name}
              </div>
            </div>
            {item.isCodable && (
              <Icons.plusCircle className="text-success-foreground" />
            )}
          </div>
        </MyItem>
      )}
    </MyComboBox>
  );
};

export default SearchForm;
