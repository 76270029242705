import { ToolbarChildrenProps } from "@draft-js-plugins/inline-toolbar/lib/components/Toolbar";
import { useCodePhraseStore } from "@store/code-phrase.store";
import { Button } from "@ui/button";
import { t } from "i18next";
import { toast } from "sonner";

function findHighlightedText(content: string, highlightedText: string) {
  // Escape special characters in the highlighted text for regex
  const escapedText = highlightedText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  
  // Create a regex pattern to match the highlighted text with HTML tags
  const regexPattern = new RegExp(`(${escapedText}(<[^>]*>)*)`, 'i');
  
  // Use regex to find the highlighted text along with HTML tags
  const match = content.match(regexPattern);
  
  // Return the matched text if found, otherwise return null
  return match ? match[0] : highlightedText;
}
const AddButtonToolbar: React.FC<ToolbarChildrenProps & {uploadedText: string}> = (props) => {
    const onMouseDown = (event: React.MouseEvent) => event.preventDefault();
    const {addPhrase} = useCodePhraseStore()
    const selection = props.getEditorState().getSelection();
      const startKey = selection.getStartKey();
      const contentState = props.getEditorState().getCurrentContent();
        const block = contentState.getBlockForKey(startKey);

        block.findStyleRanges(
          (character) => {
            const style = character.getStyle();
            return style.has('MARK'); 
          },
          (_start, _end) => {
          }
        );
      

    const onClick = async () => {
      const selection = props.getEditorState().getSelection();
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const endOffset = selection.getEndOffset();
      const contentState = props.getEditorState().getCurrentContent();
      const selectedText = contentState
        .getBlockForKey(startKey)
        .getText()
        .slice(startOffset, endOffset);
        const found = findHighlightedText(props.uploadedText, selectedText);
        addPhrase(found);
        toast(`${t("added")} "${selectedText}"`)
      return;
    };
   
    return (
      <div onMouseDown={onMouseDown} className="inline-block">
        <Button className="border-none" onClick={()=>onClick()} variant={"outline"}>
         {t("add")}
        </Button>
      </div>
    );
  };

  export default AddButtonToolbar;