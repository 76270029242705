import { Icons } from "@components/icons";
import { cn } from "@lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import { HTMLMotionProps, motion } from "framer-motion";
import * as React from "react";

const buttonVariants = cva("flex justify-center items-center", {
  variants: {
    size: {
      default: "w-6 h-6",
      sm: "w-4 h-4",
      lg: "w-10 h-10",
      xl: "w-28 h-28",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

type MotionDivProps = HTMLMotionProps<"div">;

export interface LoaderProps
  extends MotionDivProps,
    VariantProps<typeof buttonVariants> {}

export const Loader = React.forwardRef<HTMLDivElement, LoaderProps>(
  ({ className, size, ...props }, ref) => {
    return (
      <motion.div className={cn("", className)} ref={ref} {...props}>
        <Icons.spinner
          className={cn(buttonVariants({ size }), "animate-spin text-brand")}
        />
      </motion.div>
    );
  },
);

Loader.displayName = "Loader";
