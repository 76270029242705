import {Icons} from "@components/icons";
import useGetUserId from "@hooks/useGetUserId";
import {cn} from "@lib/utils";
import {MedicalReportProcessState} from "@models/medical-report-process";
import {Badge} from "@ui/badge";
import {cva} from "class-variance-authority";
import {format} from "date-fns";
import {useTranslation} from "react-i18next";
interface CaseItemProps {
  title: string;
  description?: string;
  createdAt: string;
  status: MedicalReportProcessState;
  active?: "default" | "active";
  className?: string;
  updateInfoUserId: string;
}
const sidebarItemVariants = cva(
  "bg-primary/90 rounded-t-md text-primary px-2 py-4 justify-between items-center flex border-t gap-2",
  {
    variants: {
      active: {
        default: "hover:bg-primary text-white",
        active: "bg-primary text-white",
      },
    },
    defaultVariants: {
      active: "default",
    },
  }
);

export const getStateVariant = (status?: MedicalReportProcessState) => {
  switch (status) {
    case MedicalReportProcessState.DRAFT:
      return "secondary";
    case MedicalReportProcessState.PREPROCESSING:
      return "secondary";
    case MedicalReportProcessState.READY:
      return "orange";
    case MedicalReportProcessState.IN_PROGRESS:
      return "blue";
    case MedicalReportProcessState.COMPLETED:
      return "green";
    case MedicalReportProcessState.VERIFIED:
      return "mediumgreen";
    case MedicalReportProcessState.ERROR:
      return "destructive";
    default:
      return "default";
  }
};

const CaseItem = ({
  title,
  status,
  description,
  active = "default",
  className,
  createdAt,
  updateInfoUserId
}: CaseItemProps) => {
  const {t} = useTranslation("");
  const {data: userInfo} = useGetUserId(updateInfoUserId);
  return (
    <div className="border-primary/30 rounded-t-md">
      <div
        className={cn(
          className,
          sidebarItemVariants({
            active,
          })
        )}
      >
        <div className="flex items-center">
          <Icons.medical className="w-10 h-10" />
          <div className="font-normal mx-2">
            <div className="text-sm md:text-medium">{title}</div>
            {createdAt ? (
              <div className="text-white/70 text-xs md:text-light">
                {t("created", { date: format(new Date(createdAt), "dd.MM.y"), firstname: userInfo?.firstname, lastname: userInfo?.lastname })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex space-x-2">
          <Badge
            variant={getStateVariant(status)}
            className="capitalize text-xs text-center"
          >
            {JSON.parse(t("statuses"))[status]}{" "}
            {status === MedicalReportProcessState.DRAFT ||
            status === MedicalReportProcessState.PREPROCESSING ? (
              <Icons.spinner className="animate-spin mx-1 h-4 w-4" />
            ) : null}
          </Badge>
        </div>
      </div>
      {description && (
        <div dangerouslySetInnerHTML={{__html: description.replaceAll("\n", "<br>") }} className="p-4 text-sm border-l border-r line-clamp-3 h-20">
        </div>
      )}
    </div>
  );
};

export default CaseItem;
