import { create } from "zustand";

import { MedicalReportProcessState } from "@models/medical-report-process";
import * as StorageService from "@services/storage.service";


export const STATE_OPTIONS = [
  { key: 'draft', name: MedicalReportProcessState.DRAFT, states: [MedicalReportProcessState.DRAFT, MedicalReportProcessState.PREPROCESSING] },
  { key: 'ready', name: MedicalReportProcessState.READY, states: [MedicalReportProcessState.READY] },
  { key: 'in_progress', name: MedicalReportProcessState.IN_PROGRESS, states: [MedicalReportProcessState.IN_PROGRESS] },
  { key: 'completed', name: MedicalReportProcessState.COMPLETED, states: [MedicalReportProcessState.COMPLETED] },
  { key: 'verified', name: MedicalReportProcessState.VERIFIED, states: [MedicalReportProcessState.VERIFIED] },
  { key: 'error', name: MedicalReportProcessState.ERROR, states: [MedicalReportProcessState.ERROR] },
];

interface DashboardState {
  searchString: string;
  stateFilter: string[];
  userFilter: string[];
  dateFilter: Date | null;
  setSearchString: (searchString: string) => void;
  setStateFilter: (dateFilter: string[]) => void;
  setUserFilter: (userFilter: string[]) => void;
  setDateFilter: (dateFilter: Date | null) => void;
}

export const useDashboardStore = create<DashboardState>()((set) => ({
  searchString: StorageService.getSearchString() || '',
  stateFilter: StorageService.getStateFilter() || STATE_OPTIONS
    .filter(option => option.name !== MedicalReportProcessState.VERIFIED)
    .map(option => option.key),
  userFilter: StorageService.getUserFilter() || [],
  dateFilter: StorageService.getDateFilter(),

  setSearchString: (searchString) =>{
    StorageService.setSearchString(searchString);;
    set(() => ({
      searchString,
    }));
  },

  setStateFilter: (stateFilter) =>{
    StorageService.setStateFilter(stateFilter);
    set(() => ({
      stateFilter,
    }));
  },

  setUserFilter: (userFilter) =>{
    StorageService.setUserFilter(userFilter);
    set(() => ({
      userFilter,
    }));
  },

  setDateFilter: (dateFilter) =>{
    StorageService.setDateFilter(dateFilter);
    set(() => ({
      dateFilter,
    }));
  },
}));
