import { create } from "zustand";

import { Account } from "@models/account";
import { User } from "@models/user";
import * as StorageService from "@services/storage.service";


interface UserState {
  activeUser?: User | null;
  activeAccount: null | Account;
  accounts: Account[] | null;
  activeUserToken: string | null;
  setActiveUser: (user: User | null) => void;
  setActiveUserToken: (token: string | null) => void;
  setAccounts: (accounts: Account[] | null) => void;
  setActiveAccount: (account: Account | null) => void;
}

export const useUserStore = create<UserState>()((set) => ({
  activeUser: null,
  activeAccount: null,
  accounts: null,
  activeUserToken: null,
  setActiveUserToken: (token) =>{
    StorageService.setActiveUserToken(token);
    set(() => ({
      activeUserToken: token,
    }));
  },
  setActiveUser: (user) =>{
    StorageService.setActiveUser(user);
    set(() => ({
      activeUser: user,
    }));
  },
  setAccounts: (accounts) =>{
    StorageService.setAccounts(accounts);
    set(() => ({
      accounts,
    }));
  },
  setActiveAccount: (account) =>{
    StorageService.setActiveAccount(account);
    set(() => ({
      activeAccount: account,
    }));
  },
}));
