import { OpsClass } from "@models/ops-class";
import { OpsModifier } from "@models/ops-modifier";
import { OpsClassWithModifiers } from "@models/ops-class-with-modifiers";
import { CodeSearchResult } from "@models/code-search-result";
import * as OpsAccess from "@access/ops.access";
import { QueryListOptions, QueryListResponse } from "./base-query.service";

export const getOpsClass = async (id: string): Promise<OpsClass | OpsClassWithModifiers> => {
  return await OpsAccess.getOpsClass(id);
};

export const getOpsSubClasses = async (superclassId: string | null, options?: QueryListOptions): Promise<QueryListResponse<OpsClass>> => {
  return await OpsAccess.getOpsSubClasses(superclassId, options);
};

export const getOpsModifier = async (id: string): Promise<OpsModifier> => {
  return await OpsAccess.getOpsModifier(id);
};

export const searchOps = async (searchTerm: string, options: Pick<QueryListOptions, 'limit'>): Promise<CodeSearchResult[]> => {
  return await OpsAccess.searchOps(searchTerm, options);
};
