import { Account } from "@models/account";
import { User } from "@models/user";
import * as LocalStorageAccess from "@access/local-storage.access";
import * as SessionStorageAccess from "@access/session-storage.access";
import { Theme } from "@hooks/useTheme";
import { storageKeys } from "@config/common";


export function setActiveUser(value: User | null): void {
  return setObject('user', value);
}

export function getActiveUser(): User | null {
  return getObject('user');
}

export function removeActiveUser(): void {
  return LocalStorageAccess.removeItem('user');
}

export function setAccounts(value: Account[] | null): void {
  return setObject('accounts', value);
}

export function getAccounts(): Account[] | null {
  return getObject('accounts');
}

export function setActiveAccount(value: Account | null): void {
  return setObject('activeAccount', value);
}

export function getActiveAccount(): Account | null {
  return getObject('activeAccount');
}

export function setActiveUserToken(value: string | null): void {
  return setString('token', value);
}

export function getActiveUserToken(): string | null {
  return getString('token');
}

export function setTheme(value: Theme | null): void {
  return setString(storageKeys.theme, value);
}

export function getTheme(): Theme | null {
  return getString(storageKeys.theme) as Theme | null;
}

export function setSearchString(value: string | null): void {
  return setString('searchString', value, true);
}

export function getSearchString(): string | null {
  return getString('searchString', true);
}

export function setStateFilter(value: string[] | null): void {
  return setObject('stateFilter', value, true);
}

export function getStateFilter(): string[] | null {
  return getObject('stateFilter', true);
}

export function setUserFilter(value: string[] | null): void {
  return setObject('userFilter', value);
}

export function getUserFilter(): string[] | null {
  return getObject('userFilter');
}

export function setDateFilter(value: Date | null): void {
  return setString('dateFilter', value?.toISOString() || null, true);
}

export function getDateFilter(): Date | null {
  const dateString = getString('dateFilter', true);
  return dateString ? new Date(dateString) : null;
}

function setObject<T>(key: string, value: T | null, useSession = false): void {
  if (useSession) {
    return SessionStorageAccess.setItem(key, value ? JSON.stringify(value) : null);
  } else {
    return LocalStorageAccess.setItem(key, value ? JSON.stringify(value) : null);
  }
}

function getObject<T>(key: string, useSession = false): T | null {
  let item: string | null = null;
  if (useSession) {
    item = SessionStorageAccess.getItem(key);
  } else {
    item = LocalStorageAccess.getItem(key);
  }
  return item ? JSON.parse(item) as T : null;
}

export function setString(key: string, value: string | null, useSession = false): void {
  if (useSession) {
    return SessionStorageAccess.setItem(key, value);
  } else {
    return LocalStorageAccess.setItem(key, value);
  }
}

export function getString(key: string, useSession = false): string | null {
  if (useSession) {
    return SessionStorageAccess.getItem(key);
  } else {
    return LocalStorageAccess.getItem(key);
  }
}

export function removeString(key: string, useSession = false): void {
  if (useSession) {
    return SessionStorageAccess.removeItem(key);
  } else {
    return LocalStorageAccess.removeItem(key);
  }
}
