
export const setItem = (key: string, value: string | null): void => {
  value ? sessionStorage.setItem(key, value) : removeItem(key);
};

export const getItem = (key: string): string | null => {
  return sessionStorage.getItem(key);
};

export const removeItem = (key: string): void => {
  sessionStorage.removeItem(key);
};
