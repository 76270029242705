import { cn } from "@lib/utils";
import * as React from "react";
import { Badge } from "@ui/badge";
import { Icons } from "@components/icons";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, value: initialValue = "", ...props }, ref) => {
    const [value, setValue] = React.useState(initialValue);

    const handleClear = () => {
      setValue("");
      if (props.onChange) {
        props.onChange({ ...props, target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
      }
    };

    return (
      <div 
        className={cn(
          "w-full relative",
        className,
        )}>
        <input
          type={type}
          className="flex h-10 w-full rounded-md border border-input bg-transparent px-3 py-2 pr-12 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          ref={ref}
          value={value}
          {...props}
          onChange={(e) => {
            setValue(e.target.value);
            if (props.onChange) props.onChange(e);
          }}
        />
        {value && (
          <Badge variant="default" className="ml-2 absolute right-3 top-1/2 transform -translate-y-1/2" onClick={(e) => {
            handleClear();
            e.preventDefault();
            e.stopPropagation();
          }}>
            <Icons.close className="h-3 w-3" />
          </Badge>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
