
export const setItem = (key: string, value: string | null): void => {
  value ? localStorage.setItem(key, value) : removeItem(key);
};

export const getItem = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const removeItem = (key: string): void => {
  localStorage.removeItem(key);
};
