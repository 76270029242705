import { fetcherWithToken } from "@lib/request";

import { CodeSearchResult } from "@models/code-search-result";
import { OpsClass } from "@models/ops-class";
import { OpsClassWithModifiers } from "@models/ops-class-with-modifiers";
import { OpsModifier } from "@models/ops-modifier";
import { QueryListOptions, QueryListResponse } from "@services/base-query.service";
import { toQueryStrings } from "@utils/query-options.utils";

export const getOpsClass = async (id: string): Promise<OpsClass | OpsClassWithModifiers> => {
  const { class: codeClass } = await fetcherWithToken<{ class: OpsClass | OpsClassWithModifiers }>(`${import.meta.env.VITE_API_BASE}/ops-classes/${id}`);
  return codeClass;
};

export const getOpsSubClasses = async (superclassId: string | null, options?: QueryListOptions): Promise<QueryListResponse<OpsClass>> => {
  const queryOptions = toQueryStrings(options);
  return await fetcherWithToken<QueryListResponse<OpsClass>>(
    `${import.meta.env.VITE_API_BASE}/ops-classes?superclass=${superclassId || ''}${queryOptions ? "&" + queryOptions : ""}`
  );
};

export const getOpsModifier = async (id: string): Promise<OpsModifier> => {
  return await fetcherWithToken<OpsModifier>(`${import.meta.env.VITE_API_BASE}/ops-modifiers/${id}`);
};

export const searchOps = async (searchTerm: string, options: Pick<QueryListOptions, 'limit'>): Promise<CodeSearchResult[]> => {
  const { data } = await fetcherWithToken<QueryListResponse<CodeSearchResult>>(`${import.meta.env.VITE_API_BASE}/ops/?search=${searchTerm}&limit=${options.limit || 10}`);
  return data;
};
