"use client";

import { Icons } from "@components/icons";
import { Input } from "@components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import * as UserService from "@services/user.service";
import * as AccountService from "@services/account.service";
import {Account} from "@models/account";
import { useUserStore } from "@store/user.store";
import { Button } from "@ui/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@ui/form";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { jwtDecode } from "jwt-decode";
import * as z from "zod";
import { JWT_INTERFACE } from "@typesClient/auth";

export default function Login() {
	const navigate = useNavigate();
	const { setActiveUser, setAccounts, setActiveAccount, setActiveUserToken } = useUserStore();
	const [loginState, setLoginState] = useState({ isLoading: false, error: "" });
	const { t } = useTranslation();
	const formSchema = z.object({
		email: z.string().email(t("login.email_invalid") ?? ""),
		password: z.string().min(6, t("login.password_invalid") ?? ""),
	});
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: "",
			password: "",
		},
	});

	async function onSubmit({ email, password }: z.infer<typeof formSchema>) {
		const user = await UserService.signIn({password, email});
		setActiveUserToken(user.token);
		const decodedToken: JWT_INTERFACE = jwtDecode(user.token);
		setLoginState({ ...loginState, isLoading: true });
		try {
			const userData = await UserService.getUser(decodedToken.id);
			if(!userData) {
				toast.error(t("login.error_failed_message")) 
				return;
			}
			setActiveUser(userData);
			const accountsInfosPromises = userData.accountIds?.map(async(accountId)=> {
				const accountsInfo = await AccountService.getAccount(accountId);
				return accountsInfo;
			})
			// @ts-ignore
			const accounts: Account[] = await Promise.all(accountsInfosPromises);
			setActiveAccount(accounts[0]);
			setAccounts(accounts);
			navigate("/");
		} catch (error) {
			toast.error(t("login.error_failed_message")) 
		} finally {
			setLoginState({ ...loginState, isLoading: false });
		}
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem>
							<FormLabel>{t("login.email")}</FormLabel>
							<FormControl>
								<Input placeholder="john.doe@doe.com" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="password"
					render={({ field }) => (
						<FormItem className="mt-0">
							<FormLabel>{t("login.password")}</FormLabel>
							<FormControl>
								<Input placeholder="*******" type="password" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<Button
					className="my-4 w-full"
					type="submit"
					disabled={loginState.isLoading}
				>
					{t("submit")}
					{loginState.isLoading && (
						<Icons.spinner className="mx-2 h-4 w-4 animate-spin" />
					)}
				</Button>
			</form>
		</Form>
	);
}
