import { BaseModel } from "./base-model";
import { Money } from "./money";


export enum MedicalReportProcessState {
  /** MedicalReportProcess imported by external process. */
  IMPORTING = 'importing',
  /** MedicalReportProcess ready to be preprocessed. */
  DRAFT = 'draft',
  /** MedicalReportProcess in preprocessing by AI. */
  PREPROCESSING = 'preprocessing',
  /** MedicalReportProcess preprocessed by AI and ready to be reviewed by human. */
  READY = 'ready',
  /** Review of the MedicalReportProcess started by human. */
  IN_PROGRESS = 'in_progress',
  /** Review of the MedicalReportProcess completed by human or machine. */
  COMPLETED = 'completed',
  /** MedicalReportProcess verified by human. */
  VERIFIED = 'verified',
  /** MedicalReportProcess deleted. */
  DELETED = 'deleted',
  /** Error occurred. */
  ERROR = "error",
}

export enum MedicalReportCodeState {
  UNCHECKED = 'unchecked',
  CHECKED = 'checked',
  REJECTED = 'rejected',
}

export enum MedicalReportCodeAddedBy {
  PLATFORM = 'platform',
  MANUAL = 'manual',
}

export enum MedicalReportCodeLocalization {
  LEFT = 'L',
  RIGHT = 'R',
  BOTH = 'B',
}

export interface MedicalReportCode {
  codeId: string;
  name: string;
  description: string;
  addedBy: MedicalReportCodeAddedBy;
  status: MedicalReportCodeState;
  relevance?: number,
  localization?: MedicalReportCodeLocalization;
  reason?: string[];
  clarificationNeeded?: string[];
  linkedTexts?: string[];
  tags?: string[];
}

/**
 * A sub-structure to encapsulate process-related metrics.
 */
export interface MedicalReportProcessMetrics {
  /** Processing duration in seconds. */
  duration?: number;
  
  /** Number of tokens consumed during the process. */
  tokenUsage?: { prompt: number; completion: number };
  
  /** Estimated cost of the process. */
  costEstimate?: Money;
}

export const MedicalReportProcessType = "medical_report_process";

/**
 * The MedicalReportProcess object.
 */
export interface MedicalReportProcess extends BaseModel {
  type: typeof MedicalReportProcessType;

  /** The Account this object belongs to. */
  accountId: string;

  processor: string;

  createdBy: string;
  createdByName: string;
  editedBy: string;
  editedByName: string;

  name: string;
  description?: string;
  state: MedicalReportProcessState;

  medicalReportId: string;
  icdCodes?: MedicalReportCode[];
  opsCodes?: MedicalReportCode[];
  drg?: { code: string };

  tags?: string[];
  internalTags?: string[];

  metrics?: MedicalReportProcessMetrics;

  meta?: { name: string; value: string; }[];
}
