import { axiosConfig } from "@config/request";
import axios, { AxiosRequestConfig } from "axios";

import * as StorageService from "@services/storage.service";


axios.defaults.timeout = 30000;

export interface ApiResponse<T> {
  status: number;
  response?: T;
  error?: Error;
}


export const post = async <T>(url: string, payload?: unknown): Promise<T> => {
  const { data } = await axios.post<T>(url, payload || null, {
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
    },
  });
  return data;
};
export const postWithToken = async <T>(url: string, payload?: unknown): Promise<T> => {
  const token = StorageService.getActiveUserToken();
  const { data } = await axios.post<T>(url, payload || null, axiosConfig(token));
  return data;
};

export const patch = async <T>(url: string, payload?: unknown): Promise<T> => {
  const { data } = await axios.patch<T>(url, payload || null, {
    headers: { "Cache-Control": "no-cache" },
  });
  return data;
};

export const put = async <T>(url: string, payload?: unknown): Promise<T> => {
  const { data } = await axios.put<T>(url, payload || null, {
    headers: { "Cache-Control": "no-cache" },
  });
  return data;
};

export const fetcher = async <T>(
  url: string,
  headers?: AxiosRequestConfig["headers"],
): Promise<T> => {
  const { data } = await axios.get(url, {
    headers,
  });
  return data;
};
export const fetcherWithToken = async <T>(
  url: string,
  headers?: AxiosRequestConfig["headers"],
): Promise<T> => {
  const token = StorageService.getActiveUserToken();
  const { data } = await axios.get<T>(url, axiosConfig(token, undefined, true, headers));
  return data;
};

export const remove = async <T>(url: string): Promise<T> => {
  const { data } = await axios.delete<T>(url);
  return data;
};
export const removeWithToken = async <T>(url: string): Promise<T> => {
  const token = StorageService.getActiveUserToken();
  const { data } = await axios.delete<T>(url, axiosConfig(token));
  return data;
};

export const postForm = async <T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any,
): Promise<T> => {
  const { data } = await axios.post<T>(url, payload, {
    headers: { "Content-Type": "application/json" },
  });
  return data;
};


export const putWithToken = async <T>(
  url: string,
  token: string,
  payload?: unknown,
): Promise<ApiResponse<T>> => {
  const { data, status } = await axios.put<T>(url, payload || null, axiosConfig(token));
  return { response: data, status };
};

export const deleteWithToken = async <T>(
  url: string,
  token: string,
  payload?: unknown,
): Promise<ApiResponse<T>> => {
  const { data, status } = await axios.delete<T>(url, {
    ...axiosConfig(token),
    data: payload,
  });
  return { response: data, status };
};
