import { IcdClass, IcdClassUsage } from "@models/icd-class";
import { IcdClassWithModifiers } from "@models/icd-class-with-modifiers";
import { IcdModifierClassUsage } from "@models/icd-modifier";
import { MedicalReportCode, MedicalReportCodeAddedBy, MedicalReportCodeLocalization, MedicalReportCodeState } from "@models/medical-report-process";
import { OpsClass, OpsClassUsage } from "@models/ops-class";
import { OpsClassWithModifiers } from "@models/ops-class-with-modifiers";
import { OpsModifierClassUsage } from "@models/ops-modifier";


export function composeCodeClass(
  codeClass: IcdClass | OpsClass | IcdClassWithModifiers | OpsClassWithModifiers,
): { code: string; name: string, nameLong: string; usage: IcdClassUsage | OpsClassUsage | IcdModifierClassUsage | OpsModifierClassUsage | null; } {
  let code = codeClass.code;
  let name = codeClass.name || '';
  let nameLong = codeClass.nameLong || '';
  let usage: IcdClassUsage | OpsClassUsage | IcdModifierClassUsage | OpsModifierClassUsage | null = codeClass.usage || null;

  if ('modifierClasses' in codeClass) {
    for (const modifierClass of (codeClass.modifierClasses || [])) {
      code += modifierClass.code;
      name += `: ${modifierClass.name}`;
      nameLong += `: ${modifierClass.name}`;
      usage = usage || modifierClass.usage || null;
    }
  }

  return { code, name, nameLong, usage };
}

export function createMedicalReportCode(
  codeClass: IcdClass | OpsClass | IcdClassWithModifiers | OpsClassWithModifiers,
): MedicalReportCode {
  const { code, nameLong, usage } = composeCodeClass(codeClass);

  const medicalReportCode: MedicalReportCode = {
    codeId: code,
    name: code,
    description: nameLong,
    status: MedicalReportCodeState.CHECKED,
    addedBy: MedicalReportCodeAddedBy.MANUAL,
    linkedTexts: [],
  };
  if (usage) {
    medicalReportCode.localization = MedicalReportCodeLocalization.LEFT;
  }

  return medicalReportCode;
}
