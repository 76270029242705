import { create } from "zustand";

type TriggerType = "close" | "open";
interface SidebarState {
  showSidebar: TriggerType;
  toggleSidebar: (value?: TriggerType) => void;
}

export const useSidebarStore = create<SidebarState>()((set) => ({
  showSidebar: "close",
  toggleSidebar: (value) =>
    set((state) => ({
      showSidebar: value ? value : state.showSidebar === "close" ? "open" : "close",
    })),
}));
